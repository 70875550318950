import request from '@/request'

/**
 * 简历列表
 * @returns {Promise<AxiosResponse<any>>}
 */
export const resumeList = (params) => {
  return request.get('/resume', {
    params
  })
}
